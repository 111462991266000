import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";


const BoothDataTable = () => {
  const [constituencies, setConstituencies] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectedConstituency, setSelectedConstituency] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedInterventionType, setSelectedInterventionType] = useState("");
  const [boothData, setBoothData] = useState([]);
  const [interventionCounts, setInterventionCounts] = useState({
    totalInterventions: 0,
    typeCounts: { Administrative: 0, Political: 0, Police: 0 },
  });
  const [selectedInterventionAction, setSelectedInterventionAction] =
    useState(null);
  const componentRef = useRef();

  // Fetch the list of constituencies
  useEffect(() => {
    const fetchConstituencies = async () => {
      try {
        const response = await axios.get(
          "http://3.111.197.175:5000/api/booths/get-ac-names"
        );
        setConstituencies(response.data);
      } catch (error) {
        console.error("Error fetching constituencies:", error);
      }
    };

    fetchConstituencies();
  }, []);

  useEffect(() => {
    const fetchInterventionCounts = async () => {
      try {
        // Construct query parameters based on selected filters
        const params = {};
        if (selectedConstituency) params.constituency = selectedConstituency;
        if (selectedZone) params.zone = selectedZone;
        if (selectedInterventionType)
          params.interventionType = selectedInterventionType;
        if (selectedInterventionAction)
          params.interventionAction = selectedInterventionAction;

        // Make API request with query parameters
        const response = await axios.get(
          "http://3.111.197.175:5000/api/booths/interventions/counts",
          { params } // Pass query parameters
        );

        // Update state with fetched counts
        setInterventionCounts(response.data);
      } catch (error) {
        console.error("Error fetching intervention counts:", error);
      }
    };

    fetchInterventionCounts();
  }, [
    selectedConstituency,
    selectedZone,
    selectedInterventionType,
    selectedInterventionAction,
  ]);

  const csvHeaders = [
    { label: "Constituency", key: "constituency" },
    { label: "Zone", key: "zone" },
    { label: "Booth", key: "booth" },
    { label: "Intervention Type", key: "interventionType" },
    { label: "Issues", key: "interventionIssues" },
    {
      label: "Intervention Contact Follow Up",
      key: "interventionContactFollowUp",
    },
    { label: "Intervention Action", key: "interventionAction" },
  ];

  // Fetch the list of zones
  useEffect(() => {
    const fetchZones = async () => {
      try {
        const response = await axios.get(
          "http://3.111.197.175:5000/api/booths/get-zone-names"
        );
        setZones(response.data);
      } catch (error) {
        console.error("Error fetching zones:", error);
      }
    };

    fetchZones();
  }, []);

  const handleInterventionActionChange = async (id, newValue) => {
    try {
      // Update intervention action in the backend
      await axios.put(
        `http://3.111.197.175:5000/api/booths/update-intervention-action/${id}`,
        {
          interventionAction: newValue,
        }
      );

      // Update the intervention action in the state
      setBoothData((prevData) =>
        prevData.map((data) =>
          data._id === id ? { ...data, interventionAction: newValue } : data
        )
      );
    } catch (error) {
      console.error("Error updating intervention action:", error);
    }
  };

  // Fetch intervention data based on selected filters
  useEffect(() => {
    const fetchBoothData = async () => {
      try {
        const queryParams = new URLSearchParams();

        // Append selected filters to the query string if they exist
        if (selectedConstituency)
          queryParams.append("constituency", selectedConstituency);
        if (selectedZone) queryParams.append("zone", selectedZone);
        if (selectedInterventionType)
          queryParams.append("interventionType", selectedInterventionType);
        if (selectedInterventionAction)
          queryParams.append("interventionAction", selectedInterventionAction);

        // Make the API request with the query parameters
        const response = await axios.get(
          `http://3.111.197.175:5000/api/booths/get-intervention-data?${queryParams.toString()}`
        );

        // Set the fetched data into the state
        setBoothData(response.data);
      } catch (error) {
        console.error("Error fetching booth data:", error);
        setBoothData([]); // Clear data in case of error
      }
    };

    fetchBoothData();
  }, [
    selectedConstituency,
    selectedZone,
    selectedInterventionType,
    selectedInterventionAction,
  ]);

  // Map the options for react-select
  const constituencyOptions = constituencies.map((constituency) => ({
    value: constituency,
    label: constituency,
  }));
  const zoneOptions = zones.map((zone) => ({
    value: zone,
    label: zone,
  }));
  const interventionTypeOptions = [
    { value: "Administrative", label: "Administrative" },
    { value: "Political", label: "Political" },
    { value: "Police", label: "Police" },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "250px", // Set the width of the select box
      marginBottom: "10px",
      border: "2px solid black",
      color: "black",
    }),
  };

  return (
    <div>
      <Sidebar />
      <h1>Intervention Data</h1>
      <div
        style={{
          display: "flex",
          gap: "10px",
          margin: "20px",
          justifyContent: "center",
        }}
      >
        <Select
          value={constituencyOptions.find(
            (option) => option.value === selectedConstituency
          )}
          onChange={(selectedOption) =>
            setSelectedConstituency(selectedOption?.value || "")
          }
          options={constituencyOptions}
          placeholder="Select Constituency"
          isClearable
          styles={customStyles}
        />
        <Select
          value={zoneOptions.find((option) => option.value === selectedZone)}
          onChange={(selectedOption) =>
            setSelectedZone(selectedOption?.value || "")
          }
          options={zoneOptions}
          placeholder="Select Zone"
          isClearable
          styles={customStyles}
        />
        <Select
          value={interventionTypeOptions.find(
            (option) => option.value === selectedInterventionType
          )}
          onChange={(selectedOption) =>
            setSelectedInterventionType(selectedOption?.value || "")
          }
          options={interventionTypeOptions}
          placeholder="Select Intervention Type"
          isClearable
          styles={customStyles}
        />
        <Select
          options={[
            { value: "Solved", label: "Solved" },
            { value: "Not Solved", label: "Not Solved" },
            { value: "Action Taken", label: "Action Taken" },
          ]}
          value={selectedInterventionAction}
          onChange={(option) =>
            setSelectedInterventionAction(option?.value || null)
          }
          placeholder="Select Intervention Action"
          styles={customStyles}
        />
        <div className="export-button">
          <ReactToPrint
            trigger={() => (
              <FontAwesomeIcon
                icon={faFilePdf}
                className="font-pdf"
                size="2x"
              />
            )}
            content={() => componentRef.current}
            pageStyle={`@page { margin: 5mm 5mm; }`}
            documentTitle={`Intervention_Data_${[
              selectedConstituency,
              selectedInterventionType,
              selectedZone,
              selectedInterventionAction,
            ]
              .filter(Boolean) 
              .join("_")}.csv`}
            removeAfterPrint={true}
          />
          <CSVLink
            data={boothData}
            headers={csvHeaders}
            filename={`Intervention_Data_${[
              selectedConstituency,
              selectedInterventionType,
              selectedZone,
              selectedInterventionAction,
            ]
              .filter(Boolean) 
              .join("_")}`}
          >
            <FontAwesomeIcon
              icon={faFileCsv}
              className="font-pdf"
              size="2x"
              style={{ marginLeft: "20px", color: "#325c23" }}
            />
          </CSVLink>
        </div>
      </div>

      <div style={{ margin: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            fontSize: "20px",
          }}
        >
          <p
            style={{
              border: "2px solid grey",
              padding: "15px",
              backgroundColor: "lightcyan",
              borderRadius: "20px",
            }}
          >
            Total Interventions: {interventionCounts.totalInterventions}
          </p>
          <p
            style={{
              border: "2px solid grey",
              padding: "15px",
              backgroundColor: "lightcyan",
              borderRadius: "20px",
            }}
          >
            Administrative: {interventionCounts.typeCounts.Administrative}
          </p>
          <p
            style={{
              border: "2px solid grey",
              padding: "15px",
              backgroundColor: "lightcyan",
              borderRadius: "20px",
            }}
          >
            Political: {interventionCounts.typeCounts.Political}
          </p>
          <p
            style={{
              border: "2px solid grey",
              padding: "15px",
              backgroundColor: "lightcyan",
              borderRadius: "20px",
            }}
          >
            Police: {interventionCounts.typeCounts.Police}
          </p>
        </div>
      </div>
      {/* Filters */}

      {/* Data Table */}
      {boothData.length > 0 ? (
        <div className="display-table-data" id="pdf-content" ref={componentRef}>
        <table>
          <thead>
            <tr>
              <th>Zone</th>
              <th>Constituency</th>
              <th>Booth</th>
              <th>Booth Type</th>
              <th>Intervention Type</th>
              <th>Issues</th>
              <th>Brief</th>
              <th>Contact Follow-Up</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {boothData.map((data) => {
              const getBackgroundColor = (action) => {
                switch (action) {
                  case "Solved":
                    return "#b6d7a8";
                  case "Not Solved":
                    return "#ea9999";
                  case "Action Taken":
                    return "#ffe599";
                  default:
                    return "transparent";
                }
              };
      
              return (
                <tr key={data._id}>
                  <td style={{ maxWidth: "100px" }}>{data.zone || "NA"}</td>
                  <td style={{ maxWidth: "100px" }}>{data.constituency}</td>
                  <td style={{ maxWidth: "100px" }}>{data.booth}</td>
                  <td style={{ maxWidth: "100px" }}>{data.boothType}</td>
                  <td style={{ maxWidth: "100px" }}>
                    {data.interventionType || "NA"}
                  </td>
                  <td style={{ maxWidth: "200px" }}>
                    {data.interventionIssues || "NA"}
                  </td>
                  <td style={{ maxWidth: "200px" }}>
                    {data.interventionIssueBrief || "NA"}
                  </td>
                  <td style={{ maxWidth: "100px" }}>
                    {data.interventionContactFollowUp || "NA"}
                  </td>
                  <td
                    style={{
                      maxWidth: "140px",
                      backgroundColor: getBackgroundColor(data.interventionAction || "Not Solved"),
                    }}
                  >
                    <select
                      value={data.interventionAction || "Not Solved"}
                      style={{ width: "140px" }}
                      onChange={(e) =>
                        handleInterventionActionChange(data._id, e.target.value)
                      }
                    >
                      <option value="Not Solved">Not Solved</option>
                      <option value="Solved">Solved</option>
                      <option value="Action Taken">Action Taken</option>
                    </select>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      
      ) : (
        <p style={{ margin: "20px" }}>
          No data found for the selected filters.
        </p>
      )}
    </div>
  );
};

export default BoothDataTable;
