import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import localforage from "localforage";
import "../css/create.css";
import Sidebar from "./Sidebar";

const InterventionData = () => {
  const [formData, setFormData] = useState({
    userId: "",
    constituency: "",
    booth: localStorage.getItem("selectedBooth"),
    interventionIssues: "",
    interventionIssueBrief: "",
    interventionContactFollowUp: "",
    interventionType: "",
  });
  const [acName, setAcName] = useState("");
  const [sampleName, setSampleName] = useState(false);
  const [booths, setBooths] = useState([]);
  const [boothData, setBoothData] = useState([]);
  const [userEmail, setUserEmail] = useState(""); // State to store user email
  const [selectedAc, setSelectedAc] = useState(""); // State to store selected AC
  const [userRoles, setUserRoles] = useState([]); // State to store user roles
  const [interventionData, setInterventionData] = useState([]);
  const componentRef = useRef();

  useEffect(() => {
    localforage
      .getItem("role")
      .then((roles) => {
        if (roles) {
          const acName = roles;
          fetchBoothsByConstituency(acName);
        } else {
          console.error("Error: No roles found in local storage.");
        }
      })
      .catch((error) => {
        console.error("Error fetching AC name from local storage:", error);
      });

    // Fetch user email
    localforage.getItem("email").then((email) => {
      setUserEmail(email || "");
    });

    // Fetch AC names
    localforage.getItem("roles").then((roles) => {
      if (roles) {
        setUserRoles(roles); // Set user roles
        setAcName(roles[0]); // Set the first AC name as default
      }
    });
  }, []);

  useEffect(() => {
    const booth5 = localStorage.getItem("selectedBooth");
    console.log("booth5::: ", booth5);
    setFormData({
      userId: "",
      constituency: "",
      booth: booth5,
      interventionIssues: "",
      interventionType: "",
      interventionIssueBrief: "",
      interventionContactFollowUp: "",
    });
  }, []);

  useEffect(() => {
    const booth5 = localStorage.getItem("selectedBooth");
    console.log("booth5::: ", booth5);
    setFormData({
      userId: "",
      constituency: "",
      booth: booth5,
      interventionIssues: "",
      interventionType: "",
      interventionIssueBrief: "",
      interventionContactFollowUp: "",
    });
  }, [sampleName]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAcChange = (e) => {
    const { value } = e.target;
    setSelectedAc(value);
    fetchBoothsByConstituency(value);
    fetchInterventionData(value); // Fetch intervention data on AC change
  };

  const fetchInterventionData = async (constituency) => {
    try {
      const response = await axios.get(
        `http://3.111.197.175:5000/api/booths/get-intervention-data-by-constituency/${constituency}`
      );
      setInterventionData(response.data);
    } catch (error) {
      console.error("Error fetching intervention data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Fetch booth data to check if an entry already exists for the selected time slot
      //   const response = await axios.get(
      //     `http://3.111.197.175:5000/api/booths/check-entry`,
      //     {
      //       params: {
      //         booth: formData.booth,
      //         timeSlot: formData.timeSlot,
      //       },
      //     }
      //   );

      //   if (response.data.exists) {
      //     alert("An entry already exists for this booth and time slot.");
      //     return;
      //   }

      const userId = await localforage.getItem("ID");
      localStorage.setItem("selectedBooth", formData.booth);
      const postResponse = await axios.post(
        "http://3.111.197.175:5000/api/booths/create-intervention",
        {
          ...formData,
          userId: userId,
          constituency: selectedAc,
        }
      );
      console.log(postResponse.data);
      setFormData({
        userId: "",
        constituency: "",
        booth: "",
        interventionIssues: "",
        interventionType: "",
        interventionIssueBrief: "",
        interventionContactFollowUp: "",
      });
      setSampleName(!sampleName);
    } catch (error) {
      console.error("Error creating booth:", error);
    }
  };

  const fetchBoothsByConstituency = async (selectedAc) => {
    try {
      const response = await axios.get(
        `http://3.111.197.175:5000/api/booths/get-booth-names-by-constituency/${selectedAc}`
      );
      setBooths(response.data);
    } catch (error) {
      console.error("Error fetching booths by constituency:", error);
    }
  };

  useEffect(() => {
    fetchBoothData();
  }, [formData.booth]);

  const fetchBoothData = async () => {
    if (formData.booth) {
      try {
        const response = await axios.get(
          `http://3.111.197.175:5000/api/ac/get-acdata-by-booth/${formData.booth}`
        );
        setBoothData(response.data);
      } catch (error) {
        console.error("Error fetching booth data:", error);
      }
    }
  };

  const handleTimeSlotChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      timeSlot: value,
    }));
    fetchBoothData();
  };

  // Generate available time slots dynamically based on current time
  const availableSlots = [
    "09:00AM",
    "10:00AM",
    "11:00AM",
    "12:00PM",
    "01:00PM",
    "02:00PM",
    "03:00PM",
    "04:00PM",
    "05:00PM",
    "06:00PM",
    "07:00PM",
    "08:00PM",
    "09:00PM",
  ];

  const getCurrentISTTime = () => {
    const now = new Date();
    const options = { timeZone: "Asia/Kolkata", hour12: false };
    return now.toLocaleTimeString("en-US", options).slice(0, 5);
  };

  // Function to convert time string to minutes past midnight
  const convertToMinutes = (timeString) => {
    const [time, period] = timeString.split(/(?=[AP]M)/); // Split time and period
    let [hours, minutes] = time.split(":").map(Number); // Extract hours and minutes
    if (period === "PM" && hours < 12) hours += 12; // Adjust hours for PM
    if (period === "AM" && hours === 12) hours = 0; // Adjust hours for midnight
    return hours * 60 + minutes; // Convert to minutes
  };

  // Filter available time slots based on current IST time
  const currentISTTimeInMinutes = convertToMinutes(getCurrentISTTime());
  const filteredSlots = availableSlots.filter((slot) => {
    const slotInMinutes = convertToMinutes(slot);
    return slotInMinutes < currentISTTimeInMinutes;
  });

  return (
    <div className="main-class">
      <div style={{ marginTop: "10px" }}>
        <Sidebar />
      </div>
      <div className="data-container">
        <h1>Enter Intervention</h1>
        <div className="main-ac-container">
          {/* <h2>
            Welcome!! {userEmail && userEmail.split("@")[0].toUpperCase()}
          </h2> */}
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
            <select
              name="ac"
              value={selectedAc}
              onChange={handleAcChange}
              className="input-fields"
            >
              <option value="">Select AC</option>
              {userRoles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </select>
            <select
              name="booth"
              value={formData.booth}
              onChange={handleChange}
              className="input-fields2"
            >
              <option value="">Select Booth</option>
              {booths.sort((a, b) => {
                // Extract numeric parts using split or regex
                const numA = parseInt(a.split("-")[0], 10); // Extract number before the dash
                const numB = parseInt(b.split("-")[0], 10);
                return numA - numB; // Compare numeric parts
              }).map((booth, index) => (
                <option key={index} value={booth}>
                  {booth}
                </option>
              ))}
            </select>
            <select
              name="interventionType"
              value={formData.interventionType}
              onChange={handleChange}
              className="input-fields"
            >
              <option value="">Select Intervention Type</option>
              <option value="Political">Political</option>
              <option value="Administrative">Administrative</option>
              <option value="Police">Police</option>
            </select>
          </div>
        </div>
        <form className="data-form" onSubmit={handleSubmit}>
          {/* <div className="display-table-data">
            <h2>Booth Data - {formData.booth}</h2>
            <table className="display-table-data2">
              <thead>
                <tr>
                  <th>Booth</th>
                  <th>Booth Type</th>
                  <th>Total Votes</th>
                  <th>AC Incharge Name</th>
                  <th>AC Incharge Contact</th>
                  <th>Booth Incharge Name</th>
                  <th>Booth Incharge Contact</th>
                </tr>
              </thead>
              <tbody>
                {boothData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.booth}</td>
                    <td>{data.boothType}</td>
                    <td>{data.totalVotes}</td>
                    <td>{data.acInchargeName}</td>
                    <td>{data.acInchargeContact}</td>
                    <td>
                      {data.boothInchargeName.map((incharge, i) => (
                        <div key={i}>{incharge.name}</div>
                      ))}
                    </td>
                    <td>
                      {data.boothInchargeName.map((incharge, i) => (
                        <div key={i}>{incharge.contact}</div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}

          <div className="fields-div" style={{alignItems:"center"}}>
            <select
              name="interventionIssues"
              value={formData.interventionIssues}
              onChange={handleChange}
              className="input-fields"
              style={{height:"48.5px"}}
            >
              <option value="">Select Issue</option>
              <option value="EVM / VVPAT not working">
                EVM / VVPAT not working
              </option>
              <option value="Inappropriate EVM Placement">
                Inappropriate EVM Placement
              </option>
              <option value="Ink on EVM">Ink on EVM</option>
              <option value="Room Lighting is not proper">
                Room Lighting is not proper
              </option>
              <option value="Slow Voting">Slow Voting</option>
              <option value="Violence / Disruption">
                Violence / Disruption
              </option>
              <option value="Booth Rigging by Opposition">
                Booth Rigging by Opposition
              </option>
              <option value="Campaigning within 100 m">
                Campaigning within 100 m
              </option>
              <option value="Voter intimidation">
              Voter intimidation
              </option>
              <option value="Power Outage">
              Power Outage
              </option>
              <option value="Transportation Issue">
              Transportation Issue
              </option>
              <option value="No checking of IDs">No checking of IDs</option>
              <option value="Biased Govt Officers">Biased Govt Officers</option>
              <option value="Cash Distribution">Cash Distribution</option>
              <option value="Others">Others</option>
            </select>
            <label style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
              Enter Name & Contact for Follow-up:
              <input
                type="text"
                name="interventionContactFollowUp"
                className="input-fields"
                onChange={handleChange}
                value={formData.interventionContactFollowUp}
              />
            </label>
          </div>
          <div className="fields-div">
            <textarea
              name="interventionIssueBrief"
              className="input-fields"
              placeholder="Enter Issue Brief..."
              value={formData.interventionIssueBrief}
              onChange={handleChange}
              rows="4" // Number of visible text lines
              cols="50" // Width of the textarea (optional)
            />
          </div>
          <button type="submit">Submit</button>
        </form>
        
      </div>
      <div className="display-table-data3" >
          <h2 className="data-table-intervention">Intervention Data for Constituency: {selectedAc}</h2>
          {interventionData.length > 0 ? (
            <table >
              <thead>
                <tr>
                  {/* <th>PC</th> */}
                  <th>Booth</th>
                  <th>Constituency</th>
                  <th>Intervention Type</th>
                  <th>Intervention Issues</th>
                  <th>Issue Brief</th>
                  <th>Contact Follow-Up</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {interventionData.map((data) => (
                  <tr key={data._id}>
                    {/* <td>{data.pc}</td> */}
                    <td>{data.booth}</td>
                    <td>{data.constituency}</td>
                    <td>{data.interventionType}</td>
                    <td>{data.interventionIssues}</td>
                    <td style={{wordWrap:"break-word"}}>{data.interventionIssueBrief}</td>
                    <td>{data.interventionContactFollowUp}</td>
                    <td>{data.interventionAction || "Pending"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data found for the selected constituency.</p>
          )}
        </div>
    </div>
  );
};

export default InterventionData;
