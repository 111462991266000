import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import Sidebar from "./Sidebar";

const BoothTable = () => {
  const [acs, setAcs] = useState([]);
  const [selectedAc, setSelectedAc] = useState("");
  const [timeSlots] = useState([
    "09:00AM",
    "10:00AM",
    "11:00AM",
    "12:00PM",
    "01:00PM",
    "02:00PM",
    "03:00PM",
    "04:00PM",
    "05:00PM",
    "06:00PM",
    "07:00PM",
    "08:00PM",
    "09:00PM",
  ]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [boothData, setBoothData] = useState([]);
  const [missingBoothCount, setMissingBoothCount] = useState(0);
  const componentRef = useRef();

  // Fetch ACs for dropdown
  useEffect(() => {
    const fetchAcs = async () => {
      try {
        const response = await axios.get(
          "http://3.111.197.175:5000/api/booths/get-ac-names"
        );
        setAcs(response.data);
      } catch (error) {
        console.error("Error fetching ACs:", error);
      }
    };

    fetchAcs();
  }, []);

  // Fetch booth data based on selected AC and time slot
  useEffect(() => {
    const fetchBoothData = async () => {
      if (!selectedAc) return;

      try {
        const url = selectedTimeSlot
          ? `http://3.111.197.175:5000/api/booths/get-booth-status/${selectedAc}?timeSlot=${selectedTimeSlot}`
          : `http://3.111.197.175:5000/api/booths/get-booth-status/${selectedAc}`;
        const response = await axios.get(url);

        setBoothData(response.data.boothStatus || []);
        setMissingBoothCount(response.data.missingBoothCount || 0);
      } catch (error) {
        console.error("Error fetching booth data:", error);
      }
    };

    fetchBoothData();
  }, [selectedAc, selectedTimeSlot]);

  const csvHeaders = [
    { label: "Booth", key: "boothName" },
    { label: "Booth Status", key: "status" },
  ];

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Sidebar />
      </div>
      <div style={styles.container}>
        <h1 style={styles.header}>Booth Data</h1>
        <div style={{ display: "flex", justifyContent:"center" }}>
          {/* AC Dropdown */}
          <div style={styles.dropdownContainer}>
            <label htmlFor="acDropdown" style={styles.label}>
              Select AC:
            </label>
            <select
              id="acDropdown"
              style={styles.dropdown}
              value={selectedAc}
              onChange={(e) => setSelectedAc(e.target.value)}
            >
              <option value="">-- Select AC --</option>
              {acs
                .sort((a, b) => {
                  // Extract numeric parts using split or regex
                  const numA = parseInt(a.split("-")[0], 10); // Extract number before the dash
                  const numB = parseInt(b.split("-")[0], 10);
                  return numA - numB; // Compare numeric parts
                })
                .map((ac) => (
                  <option key={ac} value={ac}>
                    {ac}
                  </option>
                ))}
            </select>
          </div>

          {/* Time Slot Dropdown */}
          <div style={styles.dropdownContainer}>
            <label htmlFor="timeSlotDropdown" style={styles.label}>
              Select Time Slot:
            </label>
            <select
              id="timeSlotDropdown"
              style={styles.dropdown}
              value={selectedTimeSlot}
              onChange={(e) => setSelectedTimeSlot(e.target.value)}
            >
              <option value="">-- All Time Slots --</option>
              {timeSlots.map((slot) => (
                <option key={slot} value={slot}>
                  {slot}
                </option>
              ))}
            </select>
          </div>

          {/* Missing Booth Count */}
          <div style={styles.countBox}>
            Missing Booths:{" "}
            <span style={styles.count}>{missingBoothCount}</span>
          </div>
          <ReactToPrint
            trigger={() => (
              <FontAwesomeIcon
                icon={faFilePdf}
                className="font-pdf"
                size="2x"
              />
            )}
            content={() => componentRef.current}
            pageStyle={`@page { margin: 5mm 5mm; }`}
            documentTitle={`Intervention_Data_${[selectedAc, selectedTimeSlot]
              .filter(Boolean)
              .join("_")}.csv`}
            removeAfterPrint={true}
          />
          <CSVLink
            data={boothData}
            headers={csvHeaders}
            filename={`Intervention_Data_${[selectedAc, selectedTimeSlot]
              .filter(Boolean)
              .join("_")}`}
          >
            <FontAwesomeIcon
              icon={faFileCsv}
              className="font-pdf"
              size="2x"
              style={{ marginLeft: "20px", color: "#325c23" }}
            />
          </CSVLink>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection:"column"
          }}
          id="pdfTable"
          ref={componentRef}
        >
          <h2>{`Ac Data - ${selectedAc} - ${selectedTimeSlot}` }</h2>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Booth Name</th>
                <th style={styles.tableHeader}>Status</th>
              </tr>
            </thead>
            <tbody>
              {boothData.length > 0 ? (
                boothData
                  .sort((a, b) => {
                    // Ensure you're accessing the boothName or the property to sort
                    const numA = parseInt(a.boothName.split("-")[0], 10); // Extract number before the dash
                    const numB = parseInt(b.boothName.split("-")[0], 10);
                    return numA - numB; // Compare numeric parts
                  })
                  .map((booth, index) => (
                    <tr key={index}>
                      <td style={styles.tableCell}>{booth.boothName}</td>
                      <td style={styles.tableCell}>
                        {booth.status === 1 ? "Data Available" : "No Data"}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td
                    colSpan="2"
                    style={{ ...styles.tableCell, textAlign: "center" }}
                  >
                    No booth data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    fontFamily: "'Arial', sans-serif",
  },
  header: {
    textAlign: "center",
    color: "#333",
    marginBottom: "20px",
  },
  dropdownContainer: {
    marginBottom: "15px",
  },
  label: {
    marginRight: "10px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  dropdown: {
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  countBox: {
    backgroundColor: "#f5f5f5",
    padding: "10px 15px",
    borderRadius: "5px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
    color: "#333",
  },
  count: {
    color: "#e74c3c",
    fontWeight: "bold",
  },
  table: {
    borderCollapse: "collapse",
    marginTop: "20px",
    maxWidth: "800px",
  },
  tableHeader: {
    backgroundColor: "#007bff",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    border: "1px solid #ddd",
  },
  tableCell: {
    padding: "10px",
    border: "1px solid #ddd",
    textAlign: "center",
  },
};

export default BoothTable;
