import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import localforage from "localforage";
import "../css/create.css";
import BoothDataDisplay from "./BoothDataDisplay";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./Sidebar";

const CreateBoothForm = () => {
  const [formData, setFormData] = useState({
    userId: "",
    constituency: "",
    booth: localStorage.getItem("selectedBooth"),
    timeSlot: "",
    polledVotes: "",
    favVotes: "",
    ubtVotes: "",
    otherVotes: "",
  });
  const [acName, setAcName] = useState("");
  const [sampleName, setSampleName] = useState(false);
  const [booths, setBooths] = useState([]);
  const [boothData, setBoothData] = useState([]);
  const [userEmail, setUserEmail] = useState(""); // State to store user email
  const [selectedAc, setSelectedAc] = useState(""); // State to store selected AC
  const [userRoles, setUserRoles] = useState([]); // State to store user roles
  const componentRef = useRef();

  useEffect(() => {
    localforage
      .getItem("role")
      .then((roles) => {
        if (roles) {
          const acName = roles;
          fetchBoothsByConstituency(acName);
        } else {
          console.error("Error: No roles found in local storage.");
        }
      })
      .catch((error) => {
        console.error("Error fetching AC name from local storage:", error);
      });

    // Fetch user email
    localforage.getItem("email").then((email) => {
      setUserEmail(email || "");
    });

    // Fetch AC names
    localforage.getItem("roles").then((roles) => {
      if (roles) {
        setUserRoles(roles); // Set user roles
        setAcName(roles[0]); // Set the first AC name as default
      }
    });
  }, []);

  useEffect(() => {
    const booth5 = localStorage.getItem("selectedBooth");
    console.log("booth5::: ", booth5);
    setFormData({
      userId: "",
      constituency: "",
      booth: booth5,
      timeSlot: "",
      polledVotes: "",
      favVotes: "",
      otherVotes: "",
    });
  }, []);

  useEffect(() => {
    const booth5 = localStorage.getItem("selectedBooth");
    console.log("booth5::: ", booth5);
    setFormData({
      userId: "",
      constituency: "",
      booth: booth5,
      timeSlot: "",
      polledVotes: "",
      favVotes: "",
      otherVotes: "",
    });
  }, [sampleName]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
  
    // Allow empty input to enable erasing
    if (value === "") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: "",
      }));
      return;
    }
  
    // Parse and validate numeric values
    if (!isNaN(value)) {
      let parsedValue = Math.max(0, parseInt(value, 10));
  
      // Restrict `polledVotes` to a maximum of 1500
      if (name === "polledVotes" && parsedValue > 1500) {
        alert("Polled Votes cannot exceed 1500.");
        parsedValue = 0;
      }
  
      // Validate sum of other votes
      const updatedFormData = { ...formData, [name]: parsedValue };
      const totalVotes =
        (name === "favVotes" ? parsedValue : updatedFormData.favVotes || 0) +
        (name === "ubtVotes" ? parsedValue : updatedFormData.ubtVotes || 0) +
        (name === "otherVotes" ? parsedValue : updatedFormData.otherVotes || 0);
  
      if (name !== "polledVotes" && totalVotes > updatedFormData.polledVotes) {
        alert(
          `The sum of Shiv Sena Votes, Opposition Votes, and Other Votes cannot exceed Polled Votes.`
        );
        return;
      }
  
      setFormData((prevState) => ({
        ...prevState,
        [name]: parsedValue,
      }));
    }
  };
  

  const handleAcChange = (e) => {
    const { value } = e.target;
    setSelectedAc(value);
    fetchBoothsByConstituency(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Fetch booth data to check if an entry already exists for the selected time slot
      const response = await axios.get(`http://3.111.197.175:5000/api/booths/check-entry`, {
        params: {
          booth: formData.booth,
          timeSlot: formData.timeSlot,
          polledVotes: formData.polledVotes, // Include polledVotes in the request
        },
      });
  
      if (response.data.exists) {
        alert("An entry already exists for this booth and time slot.");
        return;
      }
  
      if (response.data.message) {
        // Check if there's a validation message from the API
        alert(response.data.message);
        return;
      }
  
      // Get userId from localforage
      const userId = await localforage.getItem("ID");
  
      // Save selected booth to localStorage
      localStorage.setItem("selectedBooth", formData.booth);
  
      // Submit the form data to create a new booth entry
      const postResponse = await axios.post(`http://3.111.197.175:5000/api/booths/create`, {
        ...formData,
        userId: userId,
        constituency: selectedAc,
      });
  
      console.log(postResponse.data);
  
      // Reset the form
      setFormData({
        userId: "",
        constituency: "",
        booth: "",
        timeSlot: "",
        polledVotes: "",
        favVotes: "",
        ubtVotes: "",
        otherVotes: "",
      });
  
      setSampleName(!sampleName); // Trigger sampleName state change
    } catch (error) {
      console.error("Error creating booth:", error);
    }
  };
  
  

  const fetchBoothsByConstituency = async (selectedAc) => {
    try {
      const response = await axios.get(
        `http://3.111.197.175:5000/api/booths/get-booth-names-by-constituency/${selectedAc}`
      );
      setBooths(response.data);
    } catch (error) {
      console.error("Error fetching booths by constituency:", error);
    }
  };

  useEffect(() => {
    fetchBoothData();
  }, [formData.booth]);

  const fetchBoothData = async () => {
    if (formData.booth) {
      try {
        const response = await axios.get(
          `http://3.111.197.175:5000/api/ac/get-acdata-by-booth/${formData.booth}`
        );
        setBoothData(response.data);
      } catch (error) {
        console.error("Error fetching booth data:", error);
      }
    }
  };

  const handleTimeSlotChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      timeSlot: value,
    }));
    fetchBoothData();
  };

  // Generate available time slots dynamically based on current time
  const availableSlots = [
    "09:00AM",
    "10:00AM",
    "11:00AM",
    "12:00PM",
    "01:00PM",
    "02:00PM",
    "03:00PM",
    "04:00PM",
    "05:00PM",
    "06:00PM",
    "07:00PM",
    "08:00PM",
    "09:00PM",
  ];
  

  const getCurrentISTTime = () => {
    const now = new Date();
    const options = { timeZone: "Asia/Kolkata", hour12: false };
    return now.toLocaleTimeString("en-US", options).slice(0, 5);
  };

  // Function to convert time string to minutes past midnight
  const convertToMinutes = (timeString) => {
    const [time, period] = timeString.split(/(?=[AP]M)/); // Split time and period
    let [hours, minutes] = time.split(":").map(Number); // Extract hours and minutes
    if (period === "PM" && hours < 12) hours += 12; // Adjust hours for PM
    if (period === "AM" && hours === 12) hours = 0; // Adjust hours for midnight
    return hours * 60 + minutes; // Convert to minutes
  };

  // Filter available time slots based on current IST time
  const currentISTTimeInMinutes = convertToMinutes(getCurrentISTTime());
  const filteredSlots = availableSlots.filter((slot) => {
    const slotInMinutes = convertToMinutes(slot);
    return slotInMinutes < currentISTTimeInMinutes;
  });

  return (
    <div className="main-class">
      <div style={{ marginTop: "10px" }}>
        <Sidebar />
      </div>
      <div className="data-container">
        <div className="export-button">
          <ReactToPrint
            trigger={() => (
              <FontAwesomeIcon
                icon={faFilePdf}
                className="font-pdf"
                size="2x"
              />
            )}
            content={() => componentRef.current}
            pageStyle={`@page { margin: 0mm 10mm; }`}
            documentTitle="YouTube Trends in Maharashtra"
            removeAfterPrint={true}
          />
        </div>
        <h1>Enter Voter Turnout Data</h1>

        <div className="main-ac-container">
          <h2>
            Welcome!! {userEmail && userEmail.split("@")[0].toUpperCase()}
          </h2>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexWrap:"wrap"}}>
            <select
              name="ac"
              value={selectedAc}
              onChange={handleAcChange}
              className="input-fields"
            >
              <option value="">Select AC</option>
              {userRoles.map((role, index) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </select>
            <select
              name="booth"
              value={formData.booth}
              onChange={handleChange}
              className="input-fields2"
            >
              <option value="">Select Booth</option>
              {booths.sort((a, b) => {
                // Extract numeric parts using split or regex
                const numA = parseInt(a.split("-")[0], 10); // Extract number before the dash
                const numB = parseInt(b.split("-")[0], 10);
                return numA - numB; // Compare numeric parts
              }).map((booth, index) => (
                <option key={index} value={booth}>
                  {booth}
                </option>
              ))}
            </select>
          </div>
        </div>
        <form className="data-form" onSubmit={handleSubmit}>
          <div>
            
          </div>
            <h2>Booth Data - {formData.booth}</h2>
            <table className="display-table-data2">
              <thead>
                <tr>
                  <th>Booth</th>
                  <th>Booth Type</th>
                  <th>Total Votes</th>
                  <th>AC Incharge Name</th>
                  <th>AC Incharge Contact</th>
                </tr>
              </thead>
              <tbody>
                {boothData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.booth}</td>
                    <td>{data.boothType}</td>
                    <td>{data.totalVotes}</td>
                    <td>{data.acInchargeName}</td>
                    <td>{data.acInchargeContact}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          <div className="fields-div">
            <select
              name="timeSlot"
              value={formData.timeSlot}
              onChange={handleTimeSlotChange}
              className="input-fields"
              style={{ maxWidth: "170px", fontSize: "14px" }}
            >
              <option value="">Select Time Slot</option>
              {filteredSlots.map((slot, index) => (
                <option key={index} value={slot}>
                  {slot}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="polledVotes"
              className="input-fields"
              placeholder="Polled Votes"
              value={formData.polledVotes}
              onChange={handleChange2}
              style={{ maxWidth: "200px", fontSize: "14px" }}
              max="1500" // Set maximum value to 1500 directly in HTML
            />
            <input
              type="text"
              name="favVotes"
              className="input-fields"
              placeholder="Shiv Sena Votes"
              value={formData.favVotes}
              onChange={handleChange2}
              style={{ maxWidth: "200px", fontSize: "14px" }}
              max={formData.polledVotes || 1500} // Dynamically adjust maximum
            />
            <input
              type="text"
              name="ubtVotes"
              className="input-fields"
              placeholder="Opposition Votes"
              value={formData.ubtVotes}
              onChange={handleChange2}
              style={{ maxWidth: "200px", fontSize: "14px" }}
              max={formData.polledVotes || 1500}
            />
            <input
              type="text"
              name="otherVotes"
              className="input-fields"
              placeholder="Other Votes"
              value={formData.otherVotes}
              onChange={handleChange2}
              style={{ maxWidth: "200px", fontSize: "14px" }}
              max={formData.polledVotes || 1500}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>

      <div id="pdf-content" ref={componentRef}>
        <div className="display-data">
          {formData.booth && <BoothDataDisplay booth={formData.booth} />}
        </div>
      </div>
    </div>
  );
};

export default CreateBoothForm;
