import React, { useEffect, useState } from "react";
import "../css/sidebar.css";
import { useNavigate } from "react-router-dom";
import localforage from "localforage";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [role, setRole] = useState("");
  const [email, setEmail] = useState(""); // State for storing email
  const navigate = useNavigate();

  // List of allowed emails

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const logout = async () => {
    await localforage.removeItem("token");
    await localforage.removeItem("ID");
    await localforage.removeItem("email");
    await localforage.removeItem("roles");
    navigate("/");
  };

  useEffect(() => {
    const fetchUserRoleAndEmail = async () => {
      try {
        const storedRole = await localforage.getItem("role1");
        if (storedRole) {
          setRole(storedRole);
        } else {
          console.log("Role not found in localforage.");
        }

        const storedEmail = await localforage.getItem("email");
        if (storedEmail) {
          setEmail(storedEmail);
        } else {
          console.log("Email not found in localforage.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserRoleAndEmail();
  }, []);

  return (
    <div>
      <div
        className={`w3-sidebar w3-bar-block w3-card w3-animate-left ${
          sidebarOpen ? "open" : ""
        }`}
      >
        <div className="close-div">
          <button className="sidebar-close-button" onClick={closeSidebar}>
            Close &times;
          </button>
          {email !== "debjyoti@showtimeconsulting.in" &&
          email !== "samarth@showtimeconsulting.in" &&
          email !== "tushar.kashyap@showtimeconsulting.in" &&
          email !== "prasad.p@showtimeconsulting.in" &&
          email !== "debjyoti@showtimeconsulting.in" &&
          email !== "rs@showtimeconsulting.in" &&
          email !== "sonkar.shalini@showtimeconsulting.in" &&
          email !== "at@showtimeconsulting.in" &&
          email !== "anuragsaxena@showtimeconsulting.in" &&
          email !== "alimpan@showtimeconsulting.in" &&
          email !== "guttedar.kiran@showtimeconsulting.in" &&
          email !== "kashish@showtimeconsulting.in" &&
          email !== "amogh@showtimeconsulting.in" &&
          email !== "hardik.parab@showtimeconsulting.in" &&
          email !== "aditiambekar@showtimeconsulting.in" &&
          email !== "rameshwar@showtimeconsulting.in" &&
          email !== "somali@showtimeconsulting.in" &&
          email !== "vaibhavi.shelar@showtimeconsulting.in" &&
          email !== "dhruv.saoji@showtimeconsulting.in" &&
          email !== "akash.jaywant@showtimeconsulting.in" &&
          email !== "vinay.gowda@showtimeconsulting.in" &&
          email !== "nidhipawar@showtimeconsulting.in" &&
          email !== "pratikubale@showtimeconsulting.in" &&
          email !== "shreyas.uttekar@showtimeconsulting.in" &&
          email !== "akkalkuwa.ccc@stc.in" &&
          email !== "sakri.ccc@stc.in" &&
          email !== "chopda.ccc@stc.in" &&
          email !== "jalgaonrural.ccc@stc.in" &&
          email !== "erandol.ccc@stc.in" &&
          email !== "pachora.ccc@stc.in" &&
          email !== "deolali.ccc@stc.in" &&
          email !== "muktainagar.ccc@stc.in" &&
          email !== "buldhana.ccc@stc.in" &&
          email !== "sindkhedraja.ccc@stc.in" &&
          email !== "mehkar.ccc@stc.in" &&
          email !== "balapur.ccc@stc.in" &&
          email !== "risod.ccc@stc.in" &&
          email !== "daryapur.ccc@stc.in" &&
          email !== "ramtek.ccc@stc.in" &&
          email !== "bhandara.ccc@stc.in" &&
          email !== "digras.ccc@stc.in" &&
          email !== "hadgaon.ccc@stc.in" &&
          email !== "barshi.ccc@stc.in" &&
          email !== "nandednorth.ccc@stc.in" &&
          email !== "nandedsouth.ccc@stc.in" &&
          email !== "kalamnuri.ccc@stc.in" &&
          email !== "parbhani.ccc@stc.in" &&
          email !== "jalna.ccc@stc.in" &&
          email !== "sillod.ccc@stc.in" &&
          email !== "kannad.ccc@stc.in" &&
          email !== "aurangabadcentral.ccc@stc.in" &&
          email !== "aurangabadwest.ccc@stc.in" &&
          email !== "paithan.ccc@stc.in" &&
          email !== "vaijapur.ccc@stc.in" &&
          email !== "nandgaon.ccc@stc.in" &&
          email !== "malegaonouter.ccc@stc.in" &&
          email !== "palghar.ccc@stc.in" &&
          email !== "boisar.ccc@stc.in" &&
          email !== "bhiwandirural.ccc@stc.in" &&
          email !== "bhiwandieast.ccc@stc.in" &&
          email !== "kalyanwest.ccc@stc.in" &&
          email !== "ambernath.ccc@stc.in" &&
          email !== "kalyanrural.ccc@stc.in" &&
          email !== "ovalamajiwada.ccc@stc.in" &&
          email !== "kopripachpakhadi.ccc@stc.in" &&
          email !== "magathane.ccc@stc.in" &&
          email !== "vikhroli.ccc@stc.in" &&
          email !== "bhandupwest.ccc@stc.in" &&
          email !== "mankhurdshivajinagar.ccc@stc.in" &&
          email !== "jogeshwarieast.ccc@stc.in" &&
          email !== "dindoshi.ccc@stc.in" &&
          email !== "andherieast.ccc@stc.in" &&
          email !== "chandivali.ccc@stc.in" &&
          email !== "chembur.ccc@stc.in" &&
          email !== "kurla.ccc@stc.in" &&
          email !== "dharavi.ccc@stc.in" &&
          email !== "mahim.ccc@stc.in" &&
          email !== "worli.ccc@stc.in" &&
          email !== "mumbadevi.ccc@stc.in" &&
          email !== "byculla.ccc@stc.in" &&
          email !== "karjat.ccc@stc.in" &&
          email !== "alibag.ccc@stc.in" &&
          email !== "mahad.ccc@stc.in" &&
          email !== "purandar.ccc@stc.in" &&
          email !== "karmala.ccc@stc.in" &&
          email !== "sangmner.ccc@stc.in" &&
          email !== "shrirampur.ccc@stc.in" &&
          email !== "nevasa.ccc@stc.in" &&
          email !== "paranda.ccc@stc.in" &&
          email !== "sangole.ccc@stc.in" &&
          email !== "umarga.ccc@stc.in" &&
          email !== "koregaon.ccc@stc.in" &&
          email !== "patan.ccc@stc.in" &&
          email !== "dapoli.ccc@stc.in" &&
          email !== "guhagar.ccc@stc.in" &&
          email !== "ratnagiri.ccc@stc.in" &&
          email !== "sawantwadi.ccc@stc.in" &&
          email !== "rajapur.ccc@stc.in" &&
          email !== "kudal.ccc@stc.in" &&
          email !== "radhanagari.ccc@stc.in" &&
          email !== "karvir.ccc@stc.in" &&
          email !== "kolhapurnorth.ccc@stc.in" &&
          email !== "khanapur.ccc@stc.in" &&
          email !== "gansavangi.ccc@stc.in" &&
          email !== "osmanabad.ccc@stc.in" ? null : (
            <a href="/create" className="w3-bar-item w3-button">
              Add Voter Turnout
            </a>
          )}
          {email !== "debjyoti@showtimeconsulting.in" &&
          email !== "samarth@showtimeconsulting.in" &&
          email !== "tushar.kashyap@showtimeconsulting.in" &&
          email !== "prasad.p@showtimeconsulting.in" &&
          email !== "rs@showtimeconsulting.in" &&
          email !== "sonkar.shalini@showtimeconsulting.in" &&
          email !== "at@showtimeconsulting.in" &&
          email !== "anuragsaxena@showtimeconsulting.in" &&
          email !== "alimpan@showtimeconsulting.in" &&
          email !== "shreyas.uttekar@showtimeconsulting.in" &&
          email !== "guttedar.kiran@showtimeconsulting.in" &&
          email !== "kashish@showtimeconsulting.in" &&
          email !== "amogh@showtimeconsulting.in" &&
          email !== "hardik.parab@showtimeconsulting.in" &&
          email !== "aditiambekar@showtimeconsulting.in" &&
          email !== "rameshwar@showtimeconsulting.in" &&
          email !== "somali@showtimeconsulting.in" &&
          email !== "vaibhavi.shelar@showtimeconsulting.in" &&
          email !== "dhruv.saoji@showtimeconsulting.in" &&
          email !== "akash.jaywant@showtimeconsulting.in" &&
          email !== "vinay.gowda@showtimeconsulting.in" &&
          email !== "nidhipawar@showtimeconsulting.in" &&
          email !== "pratikubale@showtimeconsulting.in" &&
          email !== "alimpan@showtimeconsulting.in" ? null : (
            <a href="/boothdashboard" className="w3-bar-item w3-button">
              Voter Turnout Dashboard
            </a>
          )}
          {email !== "samarth@showtimeconsulting.in" &&
          email !== "tushar.kashyap@showtimeconsulting.in" &&
          email !== "prasad.p@showtimeconsulting.in" &&
          email !== "debjyoti@showtimeconsulting.in" &&
          email !== "rs@showtimeconsulting.in" &&
          email !== "sonkar.shalini@showtimeconsulting.in" &&
          email !== "at@showtimeconsulting.in" &&
          email !== "anuragsaxena@showtimeconsulting.in" &&
          email !== "alimpan@showtimeconsulting.in" &&
          email !== "guttedar.kiran@showtimeconsulting.in" &&
          email !== "kashish@showtimeconsulting.in" &&
          email !== "amogh@showtimeconsulting.in" &&
          email !== "hardik.parab@showtimeconsulting.in" &&
          email !== "aditiambekar@showtimeconsulting.in" &&
          email !== "rameshwar@showtimeconsulting.in" &&
          email !== "somali@showtimeconsulting.in" &&
          email !== "vaibhavi.shelar@showtimeconsulting.in" &&
          email !== "dhruv.saoji@showtimeconsulting.in" &&
          email !== "akash.jaywant@showtimeconsulting.in" &&
          email !== "vinay.gowda@showtimeconsulting.in" &&
          email !== "nidhipawar@showtimeconsulting.in" &&
          email !== "pratikubale@showtimeconsulting.in" &&
          email !== "shreyas.uttekar@showtimeconsulting.in" &&
          email !== "akkalkuwa.field@stc.in" &&
          email !== "jalgaonrural.field@stc.in" &&
          email !== "chopda.feild@stc.in" &&
          email !== "erandol.field@stc.in" &&
          email !== "pachora.field@stc.in" &&
          email !== "deolalisc.field@stc.in" &&
          email !== "muktainagar.field@stc.in" &&
          email !== "buldhana.field@stc.in" &&
          email !== "sindkhedraja.field@stc.in" &&
          email !== "mehkarsc.field@stc.in" &&
          email !== "balapur.field@stc.in" &&
          email !== "risod.field@stc.in" &&
          email !== "daryapur.field@stc.in" &&
          email !== "ramtek.field@stc.in" &&
          email !== "bhandara.field@stc.in" &&
          email !== "digras.field@stc.in" &&
          email !== "hadgaon.field@stc.in" &&
          email !== "barshi.field@stc.in" &&
          email !== "nandednorth.field@stc.in" &&
          email !== "nandedsouth.field@stc.in" &&
          email !== "kalamnuri.field@stc.in" &&
          email !== "parbhani.field@stc.in" &&
          email !== "jalna.field@stc.in" &&
          email !== "sillod.field@stc.in" &&
          email !== "kannad.field@stc.in" &&
          email !== "aurangabadcentral.field@stc.in" &&
          email !== "aurangabadwest.field@stc.in" &&
          email !== "paithan.field@stc.in" &&
          email !== "vaijapur.field@stc.in" &&
          email !== "nandgaon.field@stc.in" &&
          email !== "malegaonouter.field@stc.in" &&
          email !== "palghar.field@stc.in" &&
          email !== "boisar.field@stc.in" &&
          email !== "bhiwandirural.field@stc.in" &&
          email !== "bhiwandieast.field@stc.in" &&
          email !== "kalyanwest.field@stc.in" &&
          email !== "ambernath.field@stc.in" &&
          email !== "kalyanrural.field@stc.in" &&
          email !== "ovalamajiwada.field@stc.in" &&
          email !== "kopripachpakhadi.field@stc.in" &&
          email !== "magathane.field@stc.in" &&
          email !== "vikhroli.field@stc.in" &&
          email !== "bhandupwest.field@stc.in" &&
          email !== "mankhurdshivajinagar.field@stc.in" &&
          email !== "jogeshwarieast.field@stc.in" &&
          email !== "dindoshi.field@stc.in" &&
          email !== "andherieast.field@stc.in" &&
          email !== "chandivali.field@stc.in" &&
          email !== "chembur.field@stc.in" &&
          email !== "kurla.field@stc.in" &&
          email !== "dharavi.field@stc.in" &&
          email !== "mahim.field@stc.in" &&
          email !== "worli.field@stc.in" &&
          email !== "mumbadevi.field@stc.in" &&
          email !== "byculla.field@stc.in" &&
          email !== "karjat.field@stc.in" &&
          email !== "alibag.field@stc.in" &&
          email !== "mahad.field@stc.in" &&
          email !== "purandar.field@stc.in" &&
          email !== "karmala.field@stc.in" &&
          email !== "sangmner.field@stc.in" &&
          email !== "shrirampur.field@stc.in" &&
          email !== "nevasa.field@stc.in" &&
          email !== "umarga.field@stc.in" &&
          email !== "paranda.field@stc.in" &&
          email !== "sangole.field@stc.in" &&
          email !== "koregaon.field@stc.in" &&
          email !== "patan.field@stc.in" &&
          email !== "dapoli.field@stc.in" &&
          email !== "guhagar.field@stc.in" &&
          email !== "ratnagiri.field@stc.in" &&
          email !== "sawantwadi.field@stc.in" &&
          email !== "rajapur.field@stc.in" &&
          email !== "kudal.field@stc.in" &&
          email !== "radhanagari.field@stc.in" &&
          email !== "karvir.field@stc.in" &&
          email !== "kolhapurnorth.field@stc.in" &&
          email !== "khanapur.field@stc.in" &&
          email !== "gansavangi.field@stc.in" &&
          email !== "osmanabad.field@stc.in" &&
          email !== "sakri.field@stc.in" ? null : (
            <a href="/intervention-data" className="w3-bar-item w3-button">
              Add Intervention
            </a>
          )}
          {email !== "debjyoti@showtimeconsulting.in" &&
          email !== "samarth@showtimeconsulting.in" &&
          email !== "tushar.kashyap@showtimeconsulting.in" &&
          email !== "prasad.p@showtimeconsulting.in" &&
          email !== "rs@showtimeconsulting.in" &&
          email !== "sonkar.shalini@showtimeconsulting.in" &&
          email !== "at@showtimeconsulting.in" &&
          email !== "anuragsaxena@showtimeconsulting.in" &&
          email !== "alimpan@showtimeconsulting.in" &&
          email !== "shreyas.uttekar@showtimeconsulting.in" &&
          email !== "guttedar.kiran@showtimeconsulting.in" &&
          email !== "kashish@showtimeconsulting.in" &&
          email !== "amogh@showtimeconsulting.in" &&
          email !== "hardik.parab@showtimeconsulting.in" &&
          email !== "aditiambekar@showtimeconsulting.in" &&
          email !== "rameshwar@showtimeconsulting.in" &&
          email !== "somali@showtimeconsulting.in" &&
          email !== "vaibhavi.shelar@showtimeconsulting.in" &&
          email !== "dhruv.saoji@showtimeconsulting.in" &&
          email !== "akash.jaywant@showtimeconsulting.in" &&
          email !== "vinay.gowda@showtimeconsulting.in" &&
          email !== "nidhipawar@showtimeconsulting.in" &&
          email !== "pratikubale@showtimeconsulting.in" ? null : (
            <a href="/booth-table" className="w3-bar-item w3-button">
              Intervention Dashboard
            </a>
          )}
          {email !== "debjyoti@showtimeconsulting.in" &&
          email !== "samarth@showtimeconsulting.in" &&
          email !== "tushar.kashyap@showtimeconsulting.in" &&
          email !== "prasad.p@showtimeconsulting.in" &&
          email !== "rs@showtimeconsulting.in" &&
          email !== "sonkar.shalini@showtimeconsulting.in" &&
          email !== "at@showtimeconsulting.in" &&
          email !== "anuragsaxena@showtimeconsulting.in" &&
          email !== "alimpan@showtimeconsulting.in" &&
          email !== "shreyas.uttekar@showtimeconsulting.in" &&
          email !== "guttedar.kiran@showtimeconsulting.in" &&
          email !== "kashish@showtimeconsulting.in" &&
          email !== "amogh@showtimeconsulting.in" &&
          email !== "hardik.parab@showtimeconsulting.in" &&
          email !== "aditiambekar@showtimeconsulting.in" &&
          email !== "rameshwar@showtimeconsulting.in" &&
          email !== "somali@showtimeconsulting.in" &&
          email !== "vaibhavi.shelar@showtimeconsulting.in" &&
          email !== "dhruv.saoji@showtimeconsulting.in" &&
          email !== "akash.jaywant@showtimeconsulting.in" &&
          email !== "vinay.gowda@showtimeconsulting.in" &&
          email !== "nidhipawar@showtimeconsulting.in" &&
          email !== "pratikubale@showtimeconsulting.in" ? null : (
            <a href="/booth-status" className="w3-bar-item w3-button">
              Booth Status
            </a>
          )}
          <a href="/" className="w3-bar-item w3-button" onClick={logout}>
            Logout
          </a>
        </div>
      </div>

      <div id="main">
        <button id="openNav" className="sidebar-button" onClick={openSidebar}>
          &#9776;
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
