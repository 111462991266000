import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../css/boothDashboard.css";
import Chart from "chart.js/auto";
import Sidebar from "./Sidebar";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const BoothDashboard = () => {
  const [acNames, setAcNames] = useState([]);
  const [pcNames, setPcNames] = useState([]);

  const [selectedAc, setSelectedAc] = useState("");
  const [pcTotal, setPcTotal] = useState(0);
  const [acTotal, setAcTotal] = useState(0);
  const [boothTotal, setBoothTotal] = useState(0);
  const [totalVotes, setTotalVotes] = useState(0);
  const [totalPolledVotes, setTotalPolledVotes] = useState(0);
  const [totalFavVotes, setTotalFavVotes] = useState(0);
  const [totalUbtVotes, setTotalUbtVotes] = useState(0);
  const [totalOtherVotes, setTotalOtherVotes] = useState(0);
  const [boothTypeVotes, setBoothTypeVotes] = useState([]);
  const [favVotesData, setFavVotesData] = useState([]);
  const [ubtVotesData, setUbtVotesData] = useState([]);
  const [otherVotesData, setOtherVotesData] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [constituencyData, setConstituencyData] = useState([]);
  const [constituencyData2, setConstituencyData2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const componentRef = useRef();
  const [sortColumn, setSortColumn] = useState("constituency");
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedBoothType, setSelectedBoothType] = useState("All");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("All");

  // Fetch AC Names on component mount
  useEffect(() => {
    axios
      .get("http://3.111.197.175:5000/api/booths/get-ac-names")
      .then((response) => {
        setAcNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching AC names:", error);
        setError("Failed to load AC names");
      });
  }, []);

  // Fetch constituency data when a specific AC is selected
  const fetchDataByConstituency = (constituencyName) => {
    setLoading(true);
    setError(null);

    axios
      .get(
        `http://3.111.197.175:5000/api/booths/get-data-by-constituency/${constituencyName}`
      )
      .then((response) => {
        setConstituencyData(response.data);
        console.log(response.data, "jdadgyai");
      })
      .catch((error) => {
        console.error("Error fetching data by constituency:", error);
        setError("Failed to load constituency data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDataByConstituency2 = (constituencyName) => {
    setLoading(true);
    setError(null);
    axios
      .get(
        `http://3.111.197.175:5000/api/booths/get-summary-by-constituency/${constituencyName}`
      )
      .then((response) => {
        // Check if the response data has the summary object
        if (response.data && response.data.summary) {
          const summaryData = response.data.summary;
          const formattedData = [
            {
              constituency: constituencyName,
              // timeSlot: "N/A", // You can change this to your actual timeSlot if needed
              polledVotes: summaryData.polledVotes,
              favVotes: summaryData.favVotes,
              ubtVotes: summaryData.ubtVotes,
              otherVotes: summaryData.otherVotes,
            },
          ];
          setConstituencyData2(formattedData); // Update state with the formatted data
        } else {
          setError("No summary data found");
        }
      })
      .catch((error) => {
        console.error("Error fetching data by constituency:", error);
        setError("Failed to load constituency data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Handle selection change
  const handleSelectChange = (event) => {
    const selectedConstituency = event.target.value;
    setSelectedAc(selectedConstituency);
    fetchDataByConstituency(selectedConstituency);
    fetchDataByConstituency2(selectedConstituency);
  };

  // Fetch AC Names
  useEffect(() => {
    axios
      .get("http://3.111.197.175:5000/api/booths/get-ac-names")
      .then((response) => setAcNames(response.data))
      .catch((error) => console.error("Error fetching AC names:", error));
  }, []);

  // Fetch PC Names
  // useEffect(() => {
  //   axios
  //     .get("http://3.111.197.175:5000/api/booths/get-pc-names")
  //     .then((response) => setPcNames(response.data))
  //     .catch((error) => console.error("Error fetching PC names:", error));
  // }, []);

  // // Fetch PC Total Count
  // useEffect(() => {
  //   axios
  //     .get("http://3.111.197.175:5000/api/booths/get-pc-total")
  //     .then((response) => setPcTotal(response.data.totalCount))
  //     .catch((error) => console.error("Error fetching PC total count:", error));
  // }, []);

  // // Fetch AC Total Count
  // useEffect(() => {
  //   axios
  //     .get("http://3.111.197.175:5000/api/booths/get-ac-total")
  //     .then((response) => setAcTotal(response.data.totalCount))
  //     .catch((error) => console.error("Error fetching AC total count:", error));
  // }, []);

  // // Fetch Booth Total Count
  // useEffect(() => {
  //   axios
  //     .get("http://3.111.197.175:5000/api/booths/get-booth-total")
  //     .then((response) => setBoothTotal(response.data.totalCount))
  //     .catch((error) =>
  //       console.error("Error fetching Booth total count:", error)
  //     );
  // }, []);

  // // Fetch Total Votes
  // useEffect(() => {
  //   axios
  //     .get("http://3.111.197.175:5000/api/booths/total-votes")
  //     .then((response) => setTotalVotes(response.data.totalCount))
  //     .catch((error) => console.error("Error fetching total votes:", error));
  // }, []);

  // // Fetch Total Polled Votes
  // useEffect(() => {
  //   axios
  //     .get("http://3.111.197.175:5000/api/booths/total-polled-votes")
  //     .then((response) => setTotalPolledVotes(response.data.totalCount))
  //     .catch((error) =>
  //       console.error("Error fetching total polled votes:", error)
  //     );
  // }, []);

  // Fetch Total Fav Votes
  useEffect(() => {
    axios
      .get("http://3.111.197.175:5000/api/booths/total-fav-votes")
      .then((response) => setTotalFavVotes(response.data.totalCount))
      .catch((error) =>
        console.error("Error fetching total favorite votes:", error)
      );
  }, []);

  useEffect(() => {
    axios
      .get("http://3.111.197.175:5000/api/booths/total-other-votes")
      .then((response) => setTotalOtherVotes(response.data.totalCount))
      .catch((error) =>
        console.error("Error fetching total other votes:", error)
      );
  }, []);

  // Fetch Total Ubt Votes
  useEffect(() => {
    axios
      .get("http://3.111.197.175:5000/api/booths/total-ubt-votes")
      .then((response) => setTotalUbtVotes(response.data.totalCount))
      .catch((error) =>
        console.error("Error fetching total Opposition votes:", error)
      );
  }, []);

  useEffect(() => {
    axios
      .get("http://3.111.197.175:5000/api/booths/total-votes-by-booth-type")
      .then((response) => {
        const data = response.data;
        setBoothTypeVotes(data);
        renderChart(data); // Call renderChart with fetched data
      })
      .catch((error) =>
        console.error("Error fetching total votes by booth type:", error)
      );
  }, []);

  useEffect(() => {
    axios
      .get("http://3.111.197.175:5000/api/booths/votes-by-fav-ubt-other-percentage")
      .then((response) => {
        const data = response.data;
        setFavVotesData(data.map((item) => item.favVotesPercentage));
        setUbtVotesData(data.map((item) => item.ubtVotesPercentage));
        setOtherVotesData(data.map((item) => item.otherVotesPercentage));
        renderOtherChart(data);
      })
      .catch((error) =>
        console.error("Error fetching votes by fav, ubt, other:", error)
      );
  }, []);

  const renderOtherChart = (data) => {
    try {
      const ctx = document.getElementById("otherVotesChart");
      if (!ctx) {
        console.error("Canvas element with id 'otherVotesChart' not found.");
        return;
      }

      // Destroy existing chart if it exists
      Chart.getChart(ctx)?.destroy();

      // Preprocess the data to handle empty or invalid fields
      const processedData = data
        .filter(
          (item) =>
            item._id &&
            item.favVotesPercentage != null &&
            item.ubtVotesPercentage != null &&
            item.otherVotesPercentage != null
        )
        .map((item) => ({
          _id: item._id || "Unknown",
          favVotesPercentage: item.favVotesPercentage || 0,
          ubtVotesPercentage: item.ubtVotesPercentage || 0,
          otherVotesPercentage: item.otherVotesPercentage || 0,
        }));

      console.log("Processed data for the chart:", processedData);

      if (processedData.length === 0) {
        console.error("No valid data to render the chart.");
        return;
      }

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: processedData.map((item) => item._id),
          datasets: [
            {
              label: "Shivsena Votes Percentage",
              data: processedData.map((item) => item.favVotesPercentage),
              backgroundColor: "rgba(255, 99, 71, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.8)",
              borderWidth: 2,
            },
            {
              label: "Opposition Votes Percentage",
              data: processedData.map((item) => item.ubtVotesPercentage),
              backgroundColor: "rgba(255, 170, 0, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.8)",
              borderWidth: 2,
            },
            {
              label: "Other Votes Percentage",
              data: processedData.map((item) => item.otherVotesPercentage),
              backgroundColor: "rgba(0, 200, 255, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.8)",
              borderWidth: 2,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value) => value.toFixed(2) + "%",
              color: "#000",
              anchor: "end",
              align: "end",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: (value) => value.toFixed(2) + "%",
              },
            },
          },
        },
      });
    } catch (error) {
      console.error("Error rendering other votes chart:", error);
    }
  };

  const renderChart = (data) => {
    try {
      const ctx = document.getElementById("boothTypeChart");
      if (!ctx) {
        console.error("Canvas element with id 'boothTypeChart' not found.");
        return;
      }
  
      // Destroy existing chart if it exists
      Chart.getChart(ctx)?.destroy();
  
      // Preprocess the data to handle empty or invalid fields
      const processedData = data
        .filter(
          (item) =>
            item._id &&
            item.totalVotes != null &&
            item.totalPolledVotes != null &&
            item.polledVotesPercentage != null
        )
        .map((item) => ({
          _id: item._id || "Unknown", // Default to "Unknown" if _id is missing
          totalVotes: item.totalVotes || 0, // Default to 0 if totalVotes is missing
          totalPolledVotes: item.totalPolledVotes || 0, // Default to 0 if totalPolledVotes is missing
          polledVotesPercentage: item.polledVotesPercentage || 0, // Default to 0 if polledVotesPercentage is missing
        }));
  
      console.log("Processed data for the chart:", processedData);
  
      // Handle the case where no valid data exists
      if (processedData.length === 0) {
        console.error("No valid data to render the chart.");
        return;
      }
  
      // Create the chart
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: processedData.map((item) => item._id),
          datasets: [
            {
              label: "Polled Votes Percentage",
              data: processedData.map((item) => item.polledVotesPercentage),
              backgroundColor: "rgba(21, 102, 255, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.8)",
              borderWidth: 2,
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              formatter: (value) => value.toFixed(2) + "%",
              color: "#000",
              anchor: "end",
              align: "end",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: (value) => value.toFixed(2) + "%",
              },
            },
          },
        },
      });
    } catch (error) {
      console.error("Error rendering booth type chart:", error);
    }
  };
  
  
  

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://3.111.197.175:5000/api/booths/get-all-constituencies-data"
        );
        setConstituencies(response.data);
      } catch (error) {
        console.error("Error fetching constituency data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSort = (column) => {
    const newSortDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newSortDirection);
    setSortColumn(column);

    const sortedData = [...constituencyData].sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      // Handle numeric extraction for values like "10-Bamani"
      const numA =
        typeof aValue === "string"
          ? parseInt(aValue.split("-")[0], 10) || 0
          : aValue;
      const numB =
        typeof bValue === "string"
          ? parseInt(bValue.split("-")[0], 10) || 0
          : bValue;

      if (!isNaN(numA) && !isNaN(numB)) {
        return newSortDirection === "asc" ? numA - numB : numB - numA;
      }

      // Fallback to string comparison for non-numeric values
      if (typeof aValue === "string" && typeof bValue === "string") {
        return newSortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0; // Return 0 if values are of different types or can't be compared
    });

    // Update the data state with sorted data
    setConstituencyData(sortedData);
  };

  useEffect(() => {
    axios
      .get("http://3.111.197.175:5000/api/booths/get-all-constituencies-data") // Replace with your actual API endpoint
      .then((response) => {
        setConstituencies(response.data);
      })
      .catch((error) => {
        console.error("Error fetching constituencies:", error);
      });
  }, []);

  const handleBoothTypeChange = (event) => {
    setSelectedBoothType(event.target.value);
  };

  const handleTimeSlotChange = (event) => {
    setSelectedTimeSlot(event.target.value);
  };

  const filteredData = constituencyData.filter((record) => {
    const boothTypeMatch =
      selectedBoothType === "All" || record.boothType === selectedBoothType;
    const timeSlotMatch =
      selectedTimeSlot === "All" || record.timeSlot === selectedTimeSlot;
    return boothTypeMatch && timeSlotMatch;
  });

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Sidebar />
      </div>
      <div className="main-data-container">
        <h1>Vidhan-Sabha Election 2024</h1>

        {error && <div className="error-message">{error}</div>}
        <div
          className="dropdown-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <select
            id="constituency-select"
            value={selectedAc}
            onChange={handleSelectChange}
            className="input-fields3"
          >
            <option value="">-- Select --</option>
            {acNames
              .sort((a, b) => {
                // Extract numeric parts using split or regex
                const numA = parseInt(a.split("-")[0], 10); // Extract number before the dash
                const numB = parseInt(b.split("-")[0], 10);
                return numA - numB; // Compare numeric parts
              })
              .map((ac) => (
                <option key={ac} value={ac}>
                  {ac}
                </option>
              ))}
          </select>

          <div className="export-button">
            <ReactToPrint
              trigger={() => (
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="font-pdf"
                  size="2x"
                />
              )}
              content={() => componentRef.current}
              pageStyle={`@page { margin: 5mm 30mm; }`}
              documentTitle={`Maharashtra Polling Data_${selectedAc}`}
              removeAfterPrint={true}
            />
          </div>
        </div>
        <div
          className="main-table-container"
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
          }}
        >
          <h2>Ac-wise Polling Data</h2>
          {loading ? (
            <div className="loading">Loading data...</div>
          ) : (
            <div className="main-table-container">
              {constituencyData2.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      {/* <th>PC</th> */}
                      <th>Constituency</th>
                      {/* <th>Time Slot</th> */}
                      <th>Polled Votes</th>
                      <th>Shivsena Votes</th>
                      <th>Opposition Votes</th>
                      <th>Others Votes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {constituencyData2.map((record, index) => (
                      <tr key={index}>
                        {/* <td>{record.pc}</td> */}
                        <td>{record.constituency}</td>
                        {/* <td>{record.timeSlot}</td> */}
                        <td>{record.polledVotes}</td>
                        <td>{record.favVotes}</td>
                        <td>{record.ubtVotes}</td>
                        <td>{record.otherVotes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>No data available for the selected constituency</div>
              )}
            </div>
          )}
        </div>
        <div className="main-chart-container">
          <div className="chart-container">
            <canvas id="boothTypeChart"></canvas>
          </div>
          <div className="chart-container">
            <canvas id="otherVotesChart"></canvas>
          </div>
        </div>
        <div
          className="main-table-container"
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
          }}
        >
          <div>
            <label htmlFor="boothTypeFilter">
              Filter by Booth Type
              <select
                id="boothTypeFilter"
                value={selectedBoothType}
                onChange={handleBoothTypeChange}
              >
                <option value="All">All</option>
                <option value="Battleground">Battleground</option>
                <option value="Favourable">Favourable</option>
                <option value="Difficult">Difficult</option>
                <option value="Safe">Safe</option>
              </select>
            </label>
            <label htmlFor="timeSlotFilter">
              Filter by Time Slot
              <select
                id="timeSlotFilter"
                value={selectedTimeSlot}
                onChange={handleTimeSlotChange}
              >
                <option value="All">All</option>
                <option value="09:00AM">09:00AM</option>
                <option value="10:00AM">10:00AM</option>
                <option value="11:00AM">11:00AM</option>
                <option value="12:00PM">12:00PM</option>
                <option value="01:00PM">01:00PM</option>
                <option value="02:00PM">02:00PM</option>
                <option value="03:00PM">03:00PM</option>
                <option value="04:00PM">04:00PM</option>
                <option value="05:00PM">05:00PM</option>
                <option value="06:00PM">06:00PM</option>
                <option value="07:00PM">07:00PM</option>
                <option value="08:00PM">08:00PM</option>
                <option value="09:00PM">09:00PM</option>
              </select>{" "}
            </label>
          </div>
          {loading ? (
            <div className="loading">Loading data...</div>
          ) : (
            <div id="pdfTable" ref={componentRef}>
              <h2>{`Booth-wise Polling - ${selectedAc}`}</h2>
              <div
                className="main-table-container"
                id="pdfTable"
                ref={componentRef}
              >
                {filteredData.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th onClick={() => handleSort("booth")}>Booth</th>
                        <th>Time Slot</th>
                        <th onClick={() => handleSort("boothType")}>
                          Booth Type
                        </th>
                        <th onClick={() => handleSort("polledVotes")}>
                          Polled Votes
                        </th>
                        <th onClick={() => handleSort("favVotes")}>
                          Shivsena Votes
                        </th>
                        <th onClick={() => handleSort("ubtVotes")}>
                          Opposition Votes
                        </th>
                        <th onClick={() => handleSort("otherVotes")}>
                          Others Votes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((record, index) => (
                        <tr key={index}>
                          <td>{record.booth}</td>
                          <td>{record.timeSlot}</td>
                          <td>{record.boothType}</td>
                          <td>{record.polledVotes}</td>
                          <td>{record.favVotes}</td>
                          <td>{record.ubtVotes}</td>
                          <td>{record.otherVotes}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>No data available for the selected booth type</div>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className="main-table-container"
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        ></div>
      </div>
    </>
  );
};

export default BoothDashboard;
