import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrashAlt } from "react-icons/fa"; 
import "../css/boothDataDisplay.css";

const BoothDataDisplay = ({ booth }) => {
  const [boothDetails, setBoothDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `http://3.111.197.175:5000/api/booths/bybooth/${booth}`
        );
        const amSlots = [];
        const pmSlots = [];
        response.data.forEach((data) => {
          if (data.timeSlot.includes("AM")) {
            amSlots.push(data);
          } else {
            pmSlots.push(data);
          }
        });
        amSlots.sort((a, b) => a.timeSlot.localeCompare(b.timeSlot));
        pmSlots.sort((a, b) => a.timeSlot.localeCompare(b.timeSlot));
        const sortedDetails = [...amSlots, ...pmSlots];
        setBoothDetails(sortedDetails);
        setLoading(false);
      } catch (error) {
        setError("Error fetching booth data");
      }
    };

    if (booth) {
      fetchData();
    } else {
      setBoothDetails([]);
    }
  }, [booth]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `http://3.111.197.175:5000/api/booths/delete/${id}`
      );
      console.log(response.data);
      setBoothDetails((prevDetails) =>
        prevDetails.filter((detail) => detail._id !== id)
      );
    } catch (error) {
      console.error("Error deleting booth:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Polling Booth Status</h2>
      <table className="display-table-data" style={
        {width:"mix-content"}
      }>
        <thead>
          <tr>
            {/* <th>Constituency</th> */}
            <th>Booth</th>
            <th>Booth Type</th>
            <th>Time Slot</th>            
            <th style={{display:"none"}}>Total Votes</th>
            <th>Polled Votes</th>
            <th>Polled Votes %</th>
            <th>Shivsena Votes</th>
            <th>Shivsena Votes %</th>
            <th>Opposition Votes</th>
            <th>Opposition Votes %</th>
            <th>Others Votes</th>
            <th>Others Votes %</th>
            <th>Action</th> 
          </tr>
        </thead>
        <tbody>
          {boothDetails.length > 0 ? (
            boothDetails.map((details, index) => (
              <tr key={index}>
                {/* <td>{details.constituency}</td> */}
                <td>{details.booth}</td>
                <td>{details.boothType}</td>
                <td>{details.timeSlot}</td>
                <td style={{display:"none"}}>{details.totalVotes}</td>
                <td>{details.polledVotes}</td>
                <td>
                  {details.totalVotes && details.polledVotes
                    ? ((details.polledVotes / details.totalVotes) * 100).toFixed(2) + "%"
                    : "N/A"}
                </td>
                <td>{details.favVotes}</td>
                <td>
                  {details.polledVotes && details.favVotes
                    ? ((details.favVotes / details.polledVotes) * 100).toFixed(2) + "%"
                    : "N/A"}
                </td>
                <td>{details.ubtVotes}</td>
                <td>
                  {details.polledVotes && details.ubtVotes
                    ? ((details.ubtVotes / details.polledVotes) * 100).toFixed(2) + "%"
                    : "N/A"}
                </td>
                <td>{details.otherVotes}</td>
                <td>  
                  {details.polledVotes && details.otherVotes
                    ? ((details.otherVotes / details.polledVotes) * 100).toFixed(2) + "%"
                    : "N/A"}
                </td>
                <td>
                  <FaTrashAlt onClick={() => handleDelete(details._id)} /> 
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BoothDataDisplay;