import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "../css/signup.css";
import img from "../STC_logo-01.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const history = useNavigate();

  const handleRoleChange = (selectedRoles) => {
    setRoles(selectedRoles.map((role) => role.value));
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://3.111.197.175:5000/api/auth/signup",
        {
          email,
          password,
          roles,
        }
      );
      console.log("Sign Up success:", response.data.message);
      window.location.reload();
      } catch (error) {
      console.error("Sign Up failed:", error.response.data.message);
    }
  };

  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "mod", label: "Moderator" },
    { value: "user", label: "User" },
    { value: "1-Akkalkuwa (ST)", label: "1-Akkalkuwa (ST)" },
    { value: "5-Sakri (ST)", label: "5-Sakri (ST)" },
    { value: "10-Chopda (ST)", label: "10-Chopda (ST)" },
    { value: "14-Jalgaon Rural", label: "14-Jalgaon Rural" },
    { value: "16-Erandol", label: "16-Erandol" },
    { value: "18-Pachora", label: "18-Pachora" },
    { value: "20-Muktainagar", label: "20-Muktainagar" },
    { value: "22-Buldhana", label: "22-Buldhana" },
    { value: "24-Sindhkhed Raja", label: "24-Sindhkhed Raja" },
    { value: "25-Mehkar (SC)", label: "25-Mehkar (SC)" },
    { value: "29-Balapur", label: "29-Balapur" },
    { value: "33-Risod", label: "33-Risod" },
    { value: "40-Daryapur", label: "40-Daryapur" },
    { value: "59-Ramtek", label: "59-Ramtek" },
    { value: "61-Bhandara (SC)", label: "61-Bhandara (SC)" },
    { value: "79-Digras", label: "79-Digras" },
    { value: "84-Hadgaon", label: "84-Hadgaon" },
    { value: "86-Nanded North", label: "86-Nanded North" },
    { value: "87-Nanded South", label: "87-Nanded South" },
    { value: "93-Kalamnuri", label: "93-Kalamnuri" },
    { value: "96-Parbhani", label: "96-Parbhani" },
    { value: "100-Gansavangi", label: "100-Gansavangi" },
    { value: "101-Jalna", label: "101-Jalna" },
    { value: "104-Sillod", label: "104-Sillod" },
    { value: "105-Kannad", label: "105-Kannad" },
    { value: "107-Aurangabad (Central)", label: "107-Aurangabad (Central)" },
    {
      value: "108-Aurangabad (West) (SC)",
      label: "108-Aurangabad (West) (SC)",
    },
    { value: "110-Paithan", label: "110-Paithan" },
    { value: "112-Vaijapur", label: "112-Vaijapur" },
    { value: "113-Nandgaon", label: "113-Nandgaon" },
    { value: "115-Malegaon (Outer)", label: "115-Malegaon (Outer)" },
    { value: "126-Deolali", label: "126-Deolali" },
    { value: "130-Palghar (ST)", label: "130-Palghar (ST)" },
    { value: "131-Boisar (ST)", label: "131-Boisar (ST)" },
    { value: "134-Bhiwandi Rural (ST)", label: "134-Bhiwandi Rural (ST)" },
    { value: "137-Bhiwandi East", label: "137-Bhiwandi East" },
    { value: "138-Kalyan West", label: "138-Kalyan West" },
    { value: "140-Ambarnath (SC)", label: "140-Ambarnath (SC)" },
    { value: "144-Kalyan Rural", label: "144-Kalyan Rural" },
    { value: "146-Ovala Majiwada", label: "146-Ovala Majiwada" },
    { value: "147-Kopri Pachpakhadi", label: "147-Kopri Pachpakhadi" },
    { value: "154-Magathane", label: "154-Magathane" },
    { value: "156-Vikhroli", label: "156-Vikhroli" },
    { value: "157-Bhandup West", label: "157-Bhandup West" },
    { value: "158-Jogeshwari East", label: "158-Jogeshwari East" },
    { value: "159-Dindoshi", label: "159-Dindoshi" },
    { value: "166-Andheri East", label: "166-Andheri East" },
    { value: "168-Chandivali", label: "168-Chandivali" },
    {
      value: "171-Mankhurd Shivaji Nagar",
      label: "171-Mankhurd Shivaji Nagar",
    },
    { value: "173-Chembur", label: "173-Chembur" },
    { value: "174-Kurla (SC)", label: "174-Kurla (SC)" },
    { value: "178-Dharavi (SC)", label: "178-Dharavi (SC)" },
    { value: "181-Mahim", label: "181-Mahim" },
    { value: "182-Worli", label: "182-Worli" },
    { value: "184-Byculla", label: "184-Byculla" },
    { value: "186-Mumbadevi", label: "186-Mumbadevi" },
    { value: "189-Karjat", label: "189-Karjat" },
    { value: "192-Alibag", label: "192-Alibag" },
    { value: "194-Mahad", label: "194-Mahad" },
    { value: "202-Purandar", label: "202-Purandar" },
    { value: "217-Sangmner", label: "217-Sangmner" },
    { value: "220-Shrirampur (SC)", label: "220-Shrirampur (SC)" },
    { value: "221-Nevasa", label: "221-Nevasa" },
    { value: "240-Omerga (SC)", label: "240-Omerga (SC)" },
    { value: "242-Dharashiv", label: "242-Dharashiv" },
    { value: "243-Paranda", label: "243-Paranda" },
    { value: "244-Karmala", label: "244-Karmala" },
    { value: "246-Barshi", label: "246-Barshi" },
    { value: "253-Sangola", label: "253-Sangola" },
    { value: "257-Koregaon", label: "257-Koregaon" },
    { value: "261-Patan", label: "261-Patan" },
    { value: "263-Dapoli", label: "263-Dapoli" },
    { value: "264-Guhagar", label: "264-Guhagar" },
    { value: "266-Ratnagiri", label: "266-Ratnagiri" },
    { value: "267-Rajapur", label: "267-Rajapur" },
    { value: "269-Kudal", label: "269-Kudal" },
    { value: "270-Sawantwadi", label: "270-Sawantwadi" },
    { value: "272-Radhanagari", label: "272-Radhanagari" },
    { value: "275-Karvir", label: "275-Karvir" },
    { value: "276-Kolhapur North", label: "276-Kolhapur North" },
    { value: "286-Khanapur", label: "286-Khanapur" },
  ];

  const customStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display:
        state.selectProps.value && state.selectProps.value.length > 0
          ? "block"
          : "none",
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "2px solid #4CAF50" : "2px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 5px rgba(76, 175, 80, 0.7)" : "none",
      cursor: "pointer",
    }),
  };

  return (
    <>
      <div className="sidebar-container"></div>
      <div className="container">
        <div className="login" style={{ width: "350px", marginTop: "120px" }}>
          <img src={img} className="img" alt="STC Logo" />
          <h1>Sign Up</h1>
          <form onSubmit={handleSubmit} style={{ alignItems: "center" }}>
            <input
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: "300px" }}
            />
            <div
              className="password-input"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                marginBottom: "20px",
              }}
            >
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ width: "300px" }}
              />
              <div
                className="eye-icon"
                onClick={toggleShowPassword}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </div>
            </div>
            <div
              className="roles"
              style={{
                width: "300px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label style={{ marginBottom: "5px", textAlign: "center" }}>
                Select Roles
              </label>
              <Select
                isMulti
                options={roleOptions}
                value={roleOptions.filter((role) => roles.includes(role.value))}
                onChange={handleRoleChange}
                styles={customStyles}
              />
            </div>

            <div className="bttn">
              <button type="submit" className="button">
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
